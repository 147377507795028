import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Platform, AlertController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from '../environments/environment';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins; 

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  usuario = null;
  temporada = environment.temporada;
  constructor(
    private platform: Platform,
    public alertController: AlertController,
    private swUpdate: SwUpdate,
    private splashScreen: SplashScreen,
    private authService: AuthService,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(async () => {
        const alert = await this.alertController.create({
          header: 'Actualización disponible',
          message: 'Hay una actualización disponible, por favor actualice.',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Actualizar',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
        await alert.present();
      });
    }
    const auth = await this.authService.checkToken();
    if(auth){
      const ret = await Storage.get({ key: 'user' });
      this.usuario = JSON.parse(ret.value);
    }
  }
  logout() {
    this.authService.logout();
  }
  ngOnInit() {
    
  }
}
