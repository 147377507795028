import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService
    ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(this.auth.isAuthenticated()){
      return true;
    }else{
      return false;
    }
  }
}