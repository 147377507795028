import { Platform, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
// import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins; 
const TOKEN_KEY = 'token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  url = environment.url;
  user = null;
  authenticationState = new BehaviorSubject(false);
 
  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    // private storage: Storage,
    private router: Router,
    private plt: Platform,
    private alertController: AlertController
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  async checkToken() {
    const { value } = await Storage.get({ key: TOKEN_KEY });
    const ret = await Storage.get({ key: 'user' });
    const user = JSON.parse(ret.value);
    return new Promise((resolve) => {
      if (value) {
        let decoded = this.helper.decodeToken(value);
        let isExpired = this.helper.isTokenExpired(value);
        if (!isExpired) {
          this.user = decoded;
          this.authenticationState.next(true);
          setTimeout(() => {
            document.getElementById('nombre').innerHTML = user.name;
            if(user.role.id === 4){

              let element: HTMLElement = document.querySelector('ion-item.coordinador') as HTMLElement;
              if (element) {
                element.classList.remove('entrenadores');
              }

              let elementr: HTMLElement = document.querySelector('ion-item.coordinadorr') as HTMLElement;
              if (elementr) {
                elementr.classList.remove('entrenadores');
              }

            }
            if(user.role.id !== 4){
              let element: HTMLElement = document.querySelector('ion-item.coordinador') as HTMLElement;
              if (element) {
                element.classList.add('entrenadores');
              }

            }

            if(user.role.id === 5){
              let element: HTMLElement = document.querySelector('ion-item.coordinadorgeneral') as HTMLElement;
              if (element) {
                element.classList.remove('coordinadores');
              }
            }
            if(user.role.id !== 5){
              let element: HTMLElement = document.querySelector('ion-item.coordinadorgeneral') as HTMLElement;
              if (element) {
                element.classList.add('coordinadores');
              }
            }
          },150);
        } else {
          /* quitar token */
          this.logout();
        }
        resolve(this.authenticationState.value);
      } else {
        this.router.navigate(['/login']);
        this.authenticationState.next(false);
        resolve(this.authenticationState.value);
      }
    });

  }
 
  // register(credentials) {
  //   return this.http.post(`${this.url}/api/register`, credentials).pipe(
  //     catchError(e => {
  //       this.showAlert(e.error.msg);
  //       throw new Error(e);
  //     })
  //   );
  // }
 
  login(credentials) {
    return this.http.post(`${this.url}/auth/local`, credentials)
      .pipe(
        tap(res => {
          // this.storage.set(TOKEN_KEY, res['token']);
          Storage.set({
            key: TOKEN_KEY,
            value: res['jwt']
          });
          this.user = this.helper.decodeToken(res['jwt']);
          this.authenticationState.next(true);

          Storage.set({
            key: 'user',
            value: JSON.stringify(res['user'])
          });
        }),
        catchError(e => {
          // this.showAlert(e.error.msg);
          throw new Error(e);
        })
      );
  }
 
  async logout() {
    await Storage.clear();
    this.authenticationState.next(false);
    this.router.navigate(['/login']);
  }
 
  async isAuthenticated() {
    return await this.checkToken();
  }
 
  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }
}